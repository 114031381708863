import React, { useState } from 'react';
import TextFieldDefault from '@mui/material/TextField';
import { AsYouType } from "libphonenumber-js";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TextFieldProps } from "@mui/material/TextField/TextField";

export type TextInputProps = TextFieldProps & {
  secureTextEntry?: boolean,
  maxLength?: number,
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters',
  mask?: 'phone' | 'date' | 'number' | 'cpf' | 'credit-card-number' | 'credit-card-valid-thru' | 'username' | 'instagram' | 'time'
}

const defaultProps: TextInputProps = {
  autoCapitalize: "none",
  multiline: false,
  fullWidth: false,
};

export const TextInput = (props: TextInputProps) => {
  props = { ...defaultProps, ...props };

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const masks = {
    "username": (value: string) => (value || '').replace(/\s/g, ''),
    "instagram": (value: string) => (value || '').replace(/([\s@#-*])/gi, ''),
    "date": (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{2})(\d{1,2})?(\d{1,4})?/, '$1/$2/$3').replace(/\D+$/, '').slice(0, 10),
    "time": (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{2})?(\d{1,2})?/, '$1:$2').replace(/\D+$/, '').slice(0, 5),
    "phone": (value: string) => new AsYouType('BR').input((value)),
    "number": (value: string) => (value || '').replace(/\D+/g, ''),
    "cpf": (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{3})(\d{1,3})(\d{1,3})?(\d{1,2})?/, '$1.$2.$3-$4').replace(/\D+$/, '').slice(0, 14),
    "credit-card-number": (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{4})(\d{1,4})(\d{1,4})?(\d{1,4})?/, '$1 $2 $3 $4').replace(/\D+$/, '').slice(0, 19),
    "credit-card-valid-thru": (value: string) => (value || '').replace(/\D+/g, '').replace(/(\d{2})(\d{1,4})?/, '$1/$2').replace(/\D+$/, '').slice(0, 7)
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target?.value ?? '';

    if (props.maxLength) {
      if (value.length > props.maxLength) {
        value = value.slice(0, props.maxLength);
      }
    }

    if (props.mask) {
      if (props.mask === "phone") {
        const tmpValue = masks.phone(value).replace('+', '');
        value = tmpValue ? `+${tmpValue}` : '';
      } else value = masks[props.mask](value);
    }

    event.target.value = value;

    if (props.onChange) props.onChange(event);
  };

  const renderIcon = () => {
    if (props.secureTextEntry) {
      return (
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => {
            setShowPassword(!showPassword);
          }}
          edge="end"
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      )
    }
    return null;
  }

  return (<TextFieldDefault
      {...props}
      fullWidth={props.fullWidth}
      helperText={props.helperText}
      InputProps={{
        endAdornment: !!props.InputProps?.endAdornment ? props.InputProps.endAdornment : (!!renderIcon() && <InputAdornment position="end">{renderIcon()}</InputAdornment>),
        autoCapitalize: props.autoCapitalize,
      }}
      multiline={props.multiline}
      type={props.secureTextEntry && !showPassword ? 'password' : 'text'}
      variant="outlined"
      label={props.label}
      style={{ ...props.style }}
      value={props.value ? props.value : ""}
      onChange={onChange}
    />
  )
};
