import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Stack,
  Avatar,
  Drawer,
  Tooltip,
  Typography,
  CardActionArea,
  useTheme
} from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import { MHidden } from '../../components/@material-extend';
import sidebarConfig from './SidebarConfig';
import { useSelector } from 'react-redux';
import { UserReducerState } from '../../redux/reducers/userReducer';
import { getFirstCharacter } from '../../utils/firstCharacter';
import { useTranslation } from 'react-i18next';
import { UserAccessType } from '../../apis/Api';
import adminSidebarConfig from './AdminSideBarConfig';
import { ThemeReducerState } from '../../redux/reducers/themeReducer';
import playerManagerSidebarConfig from './PlayerManagerSIdebarConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

export const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const { profileImageUrl, name, roles } = useSelector((state: { user: UserReducerState }) => state?.user);
  const { t } = useTranslation()
  const { selectedTheme } = useSelector((state: { theme: ThemeReducerState }) => state?.theme)
  const theme = useTheme()

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleRoles = (roles?: string[]) => {

    switch (true) {
      case roles?.includes(UserAccessType.SysAdmin) && roles?.includes(UserAccessType.BackofficeAdmin):
        return adminSidebarConfig;
      case roles?.includes(UserAccessType.SysAdmin):
        return adminSidebarConfig;
      case roles?.includes(UserAccessType.BackofficeAdmin):
        return adminSidebarConfig;
      case roles?.includes(UserAccessType.Sponsor):
        return sidebarConfig;
      case roles?.includes(UserAccessType.Financial):
        return sidebarConfig;
      case roles?.includes(UserAccessType.PlayerManager):
        return playerManagerSidebarConfig;
      case roles?.includes(UserAccessType.Unset):
        return [];
      default:
        return [];
    }
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="start">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <Logo type='full' size='small' />
          </Box>

          {/*<MHidden width="lgDown">*/}
          {/*  {!isCollapse && (*/}
          {/*    <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />*/}
          {/*  )}*/}
          {/*</MHidden>*/}
        </Stack>

        {isCollapse ? (
          <Avatar
            alt="My Avatar"
            src="/static/mock-images/avatars/avatar_default.jpg"
            sx={{ mx: 'auto', mb: 2 }}
          />
        ) : (
          <Link underline="none" component={RouterLink} to="#">
            <AccountStyle>
              {
                profileImageUrl ? (
                  <Avatar alt={name} src={profileImageUrl} />
                ) : (
                  <Avatar>{getFirstCharacter(name)}</Avatar>

                )
              }              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" noWrap sx={{ color: 'text.primary', }}>
                  {name?.length! >= 12 ? `${name?.substring(0, 12)}...` : name}
                </Typography>
                {roles === undefined || roles === null || roles.length === 0 ? null : <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {t(`futstat:role:${roles === undefined ? "Unset" : roles[0]}`) || '-'}
                </Typography>}
              </Box>
            </AccountStyle>
          </Link>
        )}
      </Stack>

      <NavSection navConfig={handleRoles(roles)} isShow={!isCollapse} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
