import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthService } from '../services/AuthService';

// routes
import { PATH_BACKOFFICE } from '../routes/paths';

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated } = useAuthService();
  if (isAuthenticated) {
    return <Navigate to={PATH_BACKOFFICE.root} />;
  }
  return <>{children}</>;
}
