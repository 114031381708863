// routes
import { PATH_PLAYER, PATH_BACKOFFICE } from '../../routes/paths';

// components
import SvgIconStyle from '../../components/SvgIconStyle';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  colaboradores: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};


const adminSidebarConfig = [

  {
    subheader: `players`,
    items: [
      {
        title: `playersUpdate`,
        path: PATH_PLAYER.player.root,
        icon: <SvgIconStyle src={"/static/futstat_icon.svg"} style={{ fontSize: '24px', }} />
      },
      {
        title: `importPlayers`,
        path: PATH_PLAYER.player.import.root,
        icon: <SvgIconStyle src={"/static/futstat_icon.svg"} style={{ fontSize: '24px', }} />
      },
    ]
  }, {
    subheader: 'Marketing',
    items: [
      {
        title: `advertising`,
        path: PATH_BACKOFFICE.advertising.content.list,
        icon: <Icon icon="healthicons:i-schedule-school-date-time" style={{ fontSize: '24px' }} />
      },
      /* {
         title: 'Notificação',
         path: PATH_BACKOFFICE.notification.root,
         icon: ICONS.calendar
       }*/
    ]
  }, {
    subheader: `entity`,
    items: [
      {
        title: 'manageEntity',
        path: PATH_BACKOFFICE.entity.content.list,
        icon: <Icon icon="fluent:people-team-add-20-filled" style={{ fontSize: '24px' }} />
      },

    ]
  },

];
export default adminSidebarConfig;
