import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';

// material
import { Stack, Alert, Link, } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// components
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { MIconButton } from '../../../components/@material-extend';
import { TextInput } from "../../../components/TextInput";

// services
import { useAuthService } from "../../../services/AuthService";
import { PATH_AUTH, PATH_BACKOFFICE } from '../../../routes/paths';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ThemeReducerState } from '../../../redux/reducers/themeReducer';

type LoginFormValues = {
  login: string;
  password: string;
  afterSubmit?: string;
};

export function LoginForm() {
  const navigate = useNavigate();
  const { login } = useAuthService();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation()

  const LoginSchema = Yup.object().shape({
    login: Yup.string().email(t(`futstat:validEmail`)).required(t("futstat:emailRequired")),
    password: Yup.string().required(t("futstat:passwordRequired"))
  });

  const formik = useFormik<LoginFormValues>({
    initialValues: {
      login: '',
      password: '',

    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      await login(values.login, values.password)
        .then(() => {
          enqueueSnackbar(`${t('futstat:LoginSuccess')}`, {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),

          });
          navigate(PATH_BACKOFFICE.root)
          if (isMountedRef.current) {
            setSubmitting(false);
          }
        })
        .catch(() => {
          if (isMountedRef.current) {
            setSubmitting(false);
            setErrors({ afterSubmit: t("futstat:InvalidLogin") });
          }
        })
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, handleChange, getFieldProps } = formik;
  const { selectedTheme } = useSelector((state: { theme: ThemeReducerState }) => state.theme)

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
          <TextInput
            {...getFieldProps('login')}
            fullWidth
            sx={selectedTheme === "dark" ? {
              input: {
                '&:-webkit-autofill': {
                  WebkitBoxShadow: '0 0 0 100px #161C24 inset',
                  WebkitTextFillColor: '#fff',
                },
              },
              backgroundColor:'transparent',
              '& label.Mui-focused': {
                color: 'white',
              },
              '& .MuiOutlinedInput-root': {

                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },
            } : undefined}
            //autoComplete="email"
            autoComplete="off"
            mask="username"
            type="email"
            label={t(`futstat:emailLabel`)}
            onChange={handleChange('login')}
            error={Boolean(touched.login && errors.login)}
            helperText={touched.login && errors.login}
          />
          <TextInput
            {...getFieldProps('password')}
            fullWidth
            sx={selectedTheme === "dark" ? {
              input: {
                '&:-webkit-autofill': {
                  WebkitBoxShadow: '0 0 0 100px #161C24 inset',
                  WebkitTextFillColor: '#fff',
                },
              },
              backgroundColor:'transparent',
              '& label.Mui-focused': {
                color: 'white',
              },
              '& .MuiOutlinedInput-root': {

                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },
            } : undefined}
            secureTextEntry
            //autoComplete="current-password"
            autoComplete="off"
            type="password"
            label={t(`futstat:passwordLabel`)}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <LoadingButton
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t("futstat:Login")}
          </LoadingButton>
        </Stack>
        {/* <Stack direction="row" alignItems="center" justifyContent="end" sx={{ my: 2 }}>

          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            Forgot password?
          </Link>
  </Stack>*/}
      </Form>
    </FormikProvider>
  );
}
