

export type ThemeReducerState = {
    selectedTheme?: string
}

const initialState: ThemeReducerState = {
    selectedTheme: "light" ?? undefined
};


const themeReducer = (
    state: ThemeReducerState = initialState,
    action: any
): ThemeReducerState => {
    switch (action.type) {
        case "CHANGE_THEME": {
            return {
                ...state,
                ...action.data,
            };
        }

        default: {
            return state;
        }
    }
};
export default themeReducer;