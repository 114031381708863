import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// material
import { alpha } from '@mui/material/styles';


// routes
import { PATH_BACKOFFICE } from '../../routes/paths';

// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';

// components
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import { useAuthService } from "../../services/AuthService";
import { useSelector } from "react-redux";
import { UserReducerState } from "../../redux/reducers/userReducer";
import { getFirstCharacter } from '../../utils/firstCharacter';
import { Avatar, Box, Button, Divider, MenuItem, Switch, Typography, styled, useTheme } from '@mui/material';
import { PATH_AUTH } from '../../routes/paths';
import { useTranslation } from 'react-i18next';
import { ThemeReducerState } from '../../redux/reducers/themeReducer';
import { useThemeAction } from '../../redux/actions/themeAction';
import LanguagePopover from './LanguagePopover';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.primary.main,
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));


const MENU_OPTIONS = [
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: PATH_BACKOFFICE.settings.password.root
  }
];

export default function AccountPopover() {

  const navigate = useNavigate();

  const { profileImageUrl, name, roles } = useSelector((state: { user: UserReducerState }) => state?.user);
  const { changeTheme } = useThemeAction()
  const { selectedTheme } = useSelector((state: { theme: ThemeReducerState }) => state?.theme)

  const anchorRef = useRef(null);
  const isMountedRef = useIsMountedRef();

  const { logout } = useAuthService();
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar();
  const [defaultTheme, setTheme] = useState<string>('light')

  const [open, setOpen] = useState(false);
  const theme = useTheme()

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      if (isMountedRef.current) {
        navigate(PATH_AUTH.login);
        handleClose();
      }
      navigate(PATH_AUTH.login);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(t("futstat:logoutUnable"), { variant: 'error' });
    }
  };

  const handleChangeTheme = (value: string) => {
    setTheme(value)
    changeTheme(value)

  }

  return (
    <>
      {/*<MaterialUISwitch sx={{ m: 1, }} defaultValue={selectedTheme} checked={selectedTheme === "dark"} onChange={() => handleChangeTheme(selectedTheme === "light" ? "dark" : "light")} />*/}
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        {
          profileImageUrl ? (
            <Avatar alt={name} src={profileImageUrl} />
          ) : (
            <Avatar>{getFirstCharacter(name)}</Avatar>

          )
        }
      </MIconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {name}
          </Typography>
          <LanguagePopover />
        </Box>

        {/*<Divider sx={{ my: 1 }} />*/}

        {/*MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
            ))*/}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            {t("futstat:logout")}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
