// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_ADVERTISING = '/advertising';
const ROOTS_NOTIFICATION = '/notification';
const ROOTS_SETTINGS = '/settings'
const ROOTS_DASHBOARDD = '/dashboard'
const ROOTS_DASHBOARD = '/';
const ROOTS_USER = "/user"
const ROOTS_ADMIN = "/admin"
const ROOTS_ENTITY = "/entity"
const ROOTS_PLAYER = "/player"

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PLAYER = {
  root: path(ROOTS_PLAYER, "/"),
  player: {
    root: path(ROOTS_PLAYER, '/search'),
    import: {
      root: path(ROOTS_PLAYER, '/import')
    }
  }
}

export const PATH_BACKOFFICE = {
  root: '/',
  advertising: {
    root: path(ROOTS_ADVERTISING, '/'),
    content: {
      root: path(ROOTS_ADVERTISING, '/advertising'),
      list: path(ROOTS_ADVERTISING, '/list'),
      new: path(ROOTS_ADVERTISING, '/new'),
      edit: path(ROOTS_ADVERTISING, '/edit')
    },
    profile: {
      root: path(ROOTS_USER, '/profile'),
    }
  },
  entity: {
    root: path(ROOTS_ENTITY, '/'),
    content: {
      root: path(ROOTS_ENTITY, '/entity'),
      list: path(ROOTS_ENTITY, '/list'),
      new: path(ROOTS_ENTITY, '/new'),
      edit: path(ROOTS_ENTITY, '/edit')
    },
   
  },
  admin: {

  },
  notification: {
    root: path(ROOTS_NOTIFICATION, '/'),
    content: {
      root: path(ROOTS_NOTIFICATION, '/notification'),
      list: path(ROOTS_NOTIFICATION, '/list'),
      new: path(ROOTS_NOTIFICATION, '/new'),
      newTest: path(ROOTS_NOTIFICATION, '/newTest'),
      edit: path(ROOTS_NOTIFICATION, '/edit'),
    },
    profile: {
      root: path(ROOTS_USER, '/profile'),
    }
  },
  settings: {
    password: {
      root: path(ROOTS_SETTINGS, '/password'),
    }
  },

};

export const PATH_DASHBOARD = {
  root: '/',
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
