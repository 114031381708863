import { combineReducers } from 'redux';
import notificationReducer from './notificationReducer';
import authReducer from './authReducer';
import userReducer from './userReducer';
import themeReducer from './themeReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  notification: notificationReducer,
  theme: themeReducer
});

export default rootReducer;
