import { useDispatch } from "react-redux";


export function useThemeAction() {
    const dispatch = useDispatch();

    return {
        changeTheme: (theme: string) => {
            dispatch({
                type: "CHANGE_THEME",
                data: {
                    selectedTheme: theme,
                    // refreshToken,
                    // expiresAt: expiresAt.toISO(),
                },
            });
        },

    };
}
